import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useState } from "react";
import AOS from "aos";
import { useContext } from "react";
import { AppContext } from "../../context/AppContext";
import { useSetTitleIcon } from "../../asstes/js/utils";

const useAppEffects = () => {

    const location = useLocation().pathname;

    const { isLoading } = useContext(AppContext);

    const arrOfNotAvaliableHeader = ['/landing'];
    const arrOfNotAvaliableFooter = ['/join',];

    const [isRenderHeader, setIsRenderHeader] = useState(true);

    const [isRenderFooter, setIsRenderFooter] = useState(true);

    // Set Loading Attr To Image

    useEffect(() => {

        const images = document.querySelectorAll('img');

        images?.forEach(image => {

            return image.setAttribute('loading', 'lazy');

        });

    }, [location]);

    useEffect(() => {
        // Create a new link element to represent the favicon
        const headIcon = document.createElement('link');

        // Select all existing link elements in the head with rel="icon"
        const removeOldestLogo = document.querySelectorAll('head link[rel="icon"]');

        // Remove all existing favicon link elements
        removeOldestLogo.forEach((item) => item.remove());

        // Set the rel and href attributes of the newly created link element
        headIcon.setAttribute('rel', 'icon');
        headIcon.setAttribute('href', require('./logo.png'));

        // Append the new link element to the head of the document
        document.head.appendChild(headIcon);

    }, [location]);


    useEffect(() => {

        const body = document.body;

        if (isLoading) {

            body.style.cssText += "max-height: 100vh; overflow:hidden";

        } else {

            body.style.cssText += "max-height: initial; overflow:initial";

        }

        return () => { };

    }, [isLoading]);

    useEffect(() => {

        // To Control Toggle Show Header
        arrOfNotAvaliableHeader.includes(location) ? setIsRenderHeader(false) : setIsRenderHeader(true);

        arrOfNotAvaliableFooter.includes(location) ? setIsRenderFooter(false) : setIsRenderFooter(true);

        // Clean Up
        return () => { };

    }, [location]);

    useEffect(() => {

        AOS.init({ duration: 1000 });

        AOS.refresh();

    }, []);

    return { isRenderHeader, isRenderFooter };

}
export default function customEffect() {

    return { useAppEffects }

}
