import React from 'react'
import { Auth, HeaderRoutes } from './components'

export default function Header() {
    return (

        <header className='p-3 relative shadow-lg hidden sm:block'>

            <div className='container flex items-center justify-between'>

                <HeaderRoutes />

                <Auth />

            </div>

        </header>

    )
}
