import React from 'react';
import ReactDOM from 'react-dom/client';
import './asstes/styles/index.css';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.min.css";
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Provider } from 'react-redux';
import store from './store/store';
import { AppProvider } from './context/AppContext';


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>

    <BrowserRouter>

      <AppProvider>

        <Provider store={store}>

          <App />

        </Provider>

      </AppProvider>

    </BrowserRouter>

  </React.StrictMode>
);