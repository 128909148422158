import { SwalControlar, SecureRequest } from './controlars';
const baseUrl = "https://abram.abram.sa/api/";
const user_dashboard_url = "https://dashboard.abram.com.sa/";
const token = localStorage.getItem('token');
const Swal = new SwalControlar();
const RequestManager = new SecureRequest(token);

export {
    baseUrl,
    token,
    Swal,
    RequestManager,
    user_dashboard_url
}