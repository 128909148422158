import { createSlice } from "@reduxjs/toolkit";

const futureAuctions = createSlice({

    name: "futureAuctions",

    initialState: [],

    reducers: {

        setFutureAuctions: (state, action) => {

            return state = action.payload;

        }
    }
});

export default futureAuctions.reducer;


export const { setFutureAuctions } = futureAuctions.actions;