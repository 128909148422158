import { useRef } from 'react';
import { Skeleton } from 'primereact/skeleton';
import { AssetDetailsCard, CustomizableAuctionTile, InteractiveActionPanel } from './components';


export default function AuctionsCard({ item, loading }) {

    const auction = item;

    const confettiContainerRef = useRef();

    return (

        <div ref={confettiContainerRef} className='p-2 relative border border-[var(--secondary-text-color)] transition hover:border-[var(--primary-color)] hover:scale-[1.1] rounded-[10px] bg-white'>

            {
                loading
                    ?
                    <Skeleton className="!w-[39px] !h-[45px] !absolute left-2 top-[8px] !shadow-lg !border-2 !rounded-bl-[30px] !rounded-br-[30px] me-2 text-white flex items-center justify-center"></Skeleton>
                    :
                    null
            }

            <CustomizableAuctionTile
                loading={loading}
                auction={auction}
                confettiContainerRef={confettiContainerRef}
            />

            <AssetDetailsCard
                loading={loading}
                auction={auction}
                item={item}
            />

            {
                loading
                    ?
                    <Skeleton className="!h-[80px] !absolute !top-[50%] !left-[50%] !shadow-lg !w-[80%] translate-x-[-50%] translate-y-[-65%] rounded-[10px]"></Skeleton>
                    :
                    null
            }

            <InteractiveActionPanel
                loading={loading}
                auction={auction}
            />

        </div>
    )
}
