import { createSlice } from "@reduxjs/toolkit";

const currentAuctions = createSlice({

    name: "currentAuctions",

    initialState: [],

    reducers: {

        setCurrentAuctions: (state, action) => {

            return state = action.payload;

        }
    }
});

export default currentAuctions.reducer;


export const { setCurrentAuctions } = currentAuctions.actions;