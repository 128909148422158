import { Dialog } from 'primereact/dialog';
import LoginForm from "../LoginForm";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/free-solid-svg-icons";
import { Fragment } from "react";
import { useEffect } from "react";
import { setScrollVisibility } from "../../asstes/js/utils";

export default function LoginPopup({ visible, setVisible }) {

    useEffect(() => {

        visible ? setScrollVisibility(visible) : setScrollVisibility(visible);

    }, [visible]);

    return (

        <Fragment>

            <Dialog headerClassName="!hidden" visible={visible} className="w-[500px] max-w-[90%]" onHide={() => setVisible(false)}>

                <LoginForm classNames={'py-10'} setVisible={setVisible} />

                <button onClick={_ => setVisible(false)} className="w-[30px] h-[30px] rounded-full bg-[var(--primary-color)] absolute -right-3 -top-3 text-white flex items-center justify-center">

                    <FontAwesomeIcon icon={faClose} />

                </button>

            </Dialog>

        </Fragment>
    )
}
