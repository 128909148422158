import { createSlice } from "@reduxjs/toolkit";

const expiredAuctions = createSlice({

    name: "expiredAuctions",

    initialState: [],

    reducers: {

        setExpiredAuctions: (state, action) => {

            return state = action.payload;

        }
    }
});

export default expiredAuctions.reducer;


export const { setExpiredAuctions } = expiredAuctions.actions;