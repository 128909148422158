import React, { useEffect } from 'react'
import { BidderInfoDisplay, SequentialNumberPanel, TimeRemainingPanel } from './components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGavel } from '@fortawesome/free-solid-svg-icons';

export default function OriginBox(props) {

    const { origin, auction, index, setActiveIndex } = props;

    useEffect(() => {

        setActiveIndex && setActiveIndex(origin?.id);

    }, [origin]);

    return (
        <div data-aos="fade-up"{...props} className='relative transition flex justify-end my-20 cursor-pointer hover:!scale-[1.1]'>

            <FontAwesomeIcon icon={faGavel} className='absolute z-[99999999999] left-0 top-[-80px] sm:top-[-40px] text-[var(--secondray-color)] rotate-[270deg] text-[30px] font-medium hammer' />

            <div className='relative p-2 px-4 rounded-[10px] bg-[#fff] border border-[var(--light-bg)] flex items-center justify-between w-full md:w-[90%] lg:w-[95%]'>

                <SequentialNumberPanel index={index} />

                <BidderInfoDisplay origin={origin} />

                <TimeRemainingPanel origin={origin} auction={auction} />

            </div>

        </div>
    )
}
