import React from 'react'
import { Link } from 'react-router-dom'

export default function NotFound({ title, hideButton, hideTitle, classNames, ...props }) {
    return (
        <div {...props} className={`h-[83vh] flex items-center justify-center flex-col ${classNames}`}>

            <img src={require('../../asstes/images/not-found.gif')} className='mb-3 w-[500px] max-w-full object-contain' />

            {
                !hideTitle ? <h1 className='text-[var(--secondray-color)] font-medium text-[30px] mb-6'>{title || 'الصفحة غير موجودة'}</h1> : null
            }

            {
                !hideButton ? <Link to={'/'} className='bg-[var(--secondray-color)] text-white p-2 px-12 rounded-[5px]'>الرجوع لصفحة الرئيسية</Link> : null
            }


        </div>
    )
}
