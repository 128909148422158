import { baseUrl, RequestManager, Swal } from "../data";

class Origins {

    like(originId, navigate) {

        return RequestManager.post(`${baseUrl}user-activity/favorite/${originId}`, originId, true).then(response => {

            if (response.data?.random) {

                localStorage.setItem('register-auth', response.data.random);

                return navigate('/confrimation');

            }

        }).catch(error => null);

    }

    isOriginExist(id, navigate) {

        return RequestManager.get(`${baseUrl}main/sub-auction/${id}`).then(response => {

            if (!response.data) {

                navigate('/');

            }

        }).catch(error => null);

    }

    postVisit(originID) {

        return RequestManager.post(`${baseUrl}user-activity/favorite/${originID}`, originID, true).then(response => {

            return;

        }).catch(error => null);

    }

    subscriptionOrigin(originId, Amount) {

        return RequestManager.post(`${baseUrl}bidder/management/amount/record/${originId}`, { amount: Amount }, true).then(response => {

            return response;

        }).catch(error => Swal.rejected('خطأ', error?.response?.data?.message));

    }
    requestToSubscriptionOrigin(originId) {

        return RequestManager.post(`${baseUrl}bidder/management/subscription/store/${originId}`, null, true).then(response => {

            return Swal.success('شكرا', 'تمت ارسال الطلب');

        }).catch(error => Swal.rejected('خطأ', error?.response?.data?.message));

    }

}

export {
    Origins
}