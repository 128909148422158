import { Accordion, AccordionTab } from 'primereact/accordion';
import { headerTemplate } from './data';
import { Fragment } from 'react';
import NotFound from '../../pages/NotFound';

export default function QuestionsAccordion({ data, isIncludingTitle, isIncludingModel }) {

    return (

        <div className='mb-32 sm:mb-[50px] relative'>

            {
                isIncludingModel ?
                    <img src={require('../../asstes/images/right-angle.png')} alt='' className='absolute right-0 z-[-1]' />
                    :
                    null
            }

            <div className='container'>

                {

                    isIncludingTitle ?
                        <Fragment>
                            <h1 className='text-[var(--text-color)] text-[20px] sm:text-[40px] font-bold mb-2 text-center'>الأسئلة الشائعة</h1>

                            <h5 className='text-[16px] sm:text-[20px] text-[var(--secondary-text-color)] text-center mb-20'>يمكنك رؤيه قسم الأسئلة هناك كل ما تريد معرفته</h5>
                        </Fragment>
                        :
                        null
                }

                {
                    isIncludingTitle && !data.length
                        ?
                        <NotFound data-aos="zoom-in" hideButton={true} hideTitle={true} classNames={'h-fit'} />
                        :
                        null
                }

                <Accordion>

                    {
                        data?.map((item, index) => {
                            return (
                                <AccordionTab key={index} headerTemplate={() => headerTemplate(item, index)}>
                                    <p className="m-0 text-[13px] sm:text-[16px]">{item.answer}</p>
                                </AccordionTab>
                            )
                        })
                    }

                </Accordion>

            </div>

        </div>

    )
}
