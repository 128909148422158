import { Skeleton } from 'primereact/skeleton';
import React, { Fragment } from 'react'
import { Link } from 'react-router-dom';
import Timer from '../../../Timer/Timer';

export default function CustomizableAuctionTile({ loading, auction, confettiContainerRef }) {
    return (
        <Fragment>

            {
                loading
                    ?
                    <Skeleton className="relative !h-[252px] mb-3 rounded-[10px]"></Skeleton>
                    :
                    <div className='relative h-[252px] mb-20 rounded-[10px]'>

                        <Link to={`/auctions/auction-details?id=${auction?.id}`}>

                            <img
                                src={auction?.auction_image || require('../../../../asstes/images/default-auction-card.png')}
                                onError={(e) => {
                                    e.target.src = require('../../../../asstes/images/default-auction-card.png');
                                }}
                                alt=''
                                className='object-fill w-full h-full absolute left-0 top-0 z-0'
                            />

                        </Link>

                        <div className='flex items-center justify-end relative z-10'>

                            <div className={`w-[39px] h-[45px] absolute left-0 top-[-2px] border-2 rounded-bl-[30px] rounded-br-[30px] me-2 text-white flex items-center justify-center ${auction?.type_date === "CURRENT" ? "bg-[#19BC56]" : auction?.type_date === "EXPIRED" ? "bg-[#FF4848]" : "bg-[blue]"}`} style={{ borderColor: "rgba(212, 234, 247, 0.83)" }}>

                                <span className='text-[11px] font-medium text-white'>
                                    {auction?.type_date === "CURRENT" ? "جاري" : auction?.type_date === "EXPIRED" ? "منتهي" : "قادم"}
                                </span>

                            </div>

                        </div>

                        <div className='p-3 absolute bottom-[-50px] z-10 w-full'>

                            <div className='flex items-center justify-between mb-3'>

                                <h3 className='text-[15px] flex items-center text-white'>

                                    <svg xmlns="http://www.w3.org/2000/svg" width="15" height="16" viewBox="0 0 15 16" fill="none">
                                        <path d="M13.125 7.25C13.125 11.625 7.5 15.375 7.5 15.375C7.5 15.375 1.875 11.625 1.875 7.25C1.875 5.75816 2.46763 4.32742 3.52252 3.27252C4.57742 2.21763 6.00816 1.625 7.5 1.625C8.99184 1.625 10.4226 2.21763 11.4775 3.27252C12.5324 4.32742 13.125 5.75816 13.125 7.25Z" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M7.5 9.125C8.53553 9.125 9.375 8.28553 9.375 7.25C9.375 6.21447 8.53553 5.375 7.5 5.375C6.46447 5.375 5.625 6.21447 5.625 7.25C5.625 8.28553 6.46447 9.125 7.5 9.125Z" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
                                    </svg>

                                    <span className='ms-2'>{auction?.Location_city}</span>

                                </h3>

                                <h3 className='text-[15px] text-white'>{auction?.Location_country}</h3>

                            </div>

                            <div className='flex items-center justify-around'>

                                <div className="num-container relative">

                                    <span className='absolute left-[50%] top-[50%] translate-x-[-50%] translate-y-[-50%] -z-1'>

                                        <svg xmlns="http://www.w3.org/2000/svg" width="38" height="36" viewBox="0 0 38 36" fill="none">

                                            <path d="M1.40081 10.7889C0.939254 5.25026 5.31011 0.5 10.868 0.5H27.9806C33.5906 0.5 37.9789 5.33562 37.436 10.9193L35.8805 26.9193C35.4072 31.787 31.3157 35.5 26.4251 35.5H12.2013C7.26049 35.5 3.14446 31.7127 2.73414 26.7889L1.40081 10.7889Z" fill="white" stroke="#DEDEDE" />
                                        </svg>

                                    </span>

                                    <h1 className="text-[#3B3C3E] font-medium relative z-10">{auction?.id}</h1>

                                </div>

                                <Timer confettiContainerRef={confettiContainerRef} auction={auction} endTime={auction?.end_date} classNames={'md:min-w-[70%] lg:min-w-[85%]'} />

                            </div>

                        </div>

                    </div>
            }

        </Fragment>
    )
}
