import darkRoundedBox from '../../../../asstes/images/dark-rounded-box.svg';

export default function SequentialNumberPanel({ index }) {
    return (
        <div className="num-container absolute top-[50%] translate-y-[-50%]  -right-12 !hidden md:!block">

            <span className='absolute left-[50%] top-[50%] translate-x-[-50%] translate-y-[-50%] -z-1'>

                <img src={darkRoundedBox} alt='' />

            </span>

            <h1 className="text-white mt-[-15px] font-medium relative z-10">{index || 1}</h1>

        </div>
    )
}
