import { useEffect, useState } from 'react';
import { NotificationService } from '../../apis/apis';
import { isLoggedIn } from '../../asstes/js/utils';


const useDataGetter = _ => {

    const isLogin = isLoggedIn();

    const [notficationVisible, setNotficationVisible] = useState(false);

    const [data, setData] = useState({});

    const matchMedia = window.matchMedia('(max-width: 768px)');

    const notficaitionsUtailty = new NotificationService();

    useEffect(() => {

        if (isLogin && matchMedia.matches) {

            isLogin && notficaitionsUtailty.fetchNotifications(setData);

        }

        return () => { };
    }, []);

    useEffect(() => {

        if (data?.totalNullReadAt && notficationVisible && isLogin) {

            notficaitionsUtailty.updateNotifications().then(_ => data.totalNullReadAt = 0);

        }

        return () => { }

    }, [notficationVisible])

    useEffect(() => {

        const closePopup = () => setNotficationVisible(false);

        window.addEventListener('click', closePopup);

        // Clean Up
        return () => window.removeEventListener('click', closePopup);

    }, []);


    return {
        notficationVisible,
        setNotficationVisible,
        isLogin,
        data,
    }
}

export {
    useDataGetter
}