import { Checkbox } from 'primereact/checkbox'
import { Link } from 'react-router-dom'
import { useDataGetter } from './data';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/free-regular-svg-icons';
import { useState } from 'react';

export default function LoginForm({ classNames, setVisible }) {

    const { data, checked, setChecked } = useDataGetter(setVisible);

    const [toggleVisible, setToggleVisible] = useState(false);

    return (
        <div data-aos="fade-down" className={`relative col-span-12 lg:col-span-6 flex items-center justify-center flex-col select-none ${classNames}`}>

            <img src={require('../../asstes/images/stars.png')} alt='' className='absolute hidden lg:block left-0 top-0 w-full h-full object-fill z-[-1] opacity-40' />

            <h1 className='text-[25px] sm:text-[40px] text-[var(--text-color)] mt-10 lg:mt-0 mb-10 font-medium'>مرحباً بك مجدداً</h1>

            <form className="w-full sm:w-fit p-4 sm:p-0 mb-10 sm:mb-0" action='' onSubmit={data.handleSubmit}>

                <div className='mb-5'>

                    <label className='text-[var(--gray-color)] block text-[14px] font-medium mb-2'>رقم الهوية</label>

                    <input autoFocus maxLength={10} onChange={data.handleChange} value={data.values.national_id} name="national_id" type='text' className='border border-[#d6ebf6] bg-[#fafafa] p-3 text-[13px] w-full sm:w-[370px] placeholder:text-[#C8C8C8] rounded-[5px] ' placeholder='رقم الهوية' />

                </div>

                <div className='mb-5'>

                    <label className='text-[var(--gray-color)] block text-[14px] font-medium mb-2'>ادخل كلمة المرور المختارة </label>

                    <div className='relative'>

                        <input type={toggleVisible ? "text" : 'password'} onChange={data.handleChange} value={data.values.password} name="password" className='border border-[#d6ebf6] bg-[#fafafa] p-3 text-[13px] w-full sm:w-[370px] placeholder:text-[#C8C8C8] rounded-[5px] ' placeholder='ادخل كلمة المرور المختارة ' />

                        <FontAwesomeIcon onClick={_ => setToggleVisible(perv => !perv)} icon={faEye} className='absolute left-2 top-[50%] translate-y-[-50%] text-[#b8daec] cursor-pointer' />

                    </div>

                </div>

                <div className="mb-20">

                    <Checkbox inputId="check-box" className="cursor-pointer" onChange={e => setChecked(e.checked)} checked={checked}></Checkbox>

                    <label htmlFor="check-box" className='text-[var(--gray-color)] text-[14px] font-medium cursor-pointer ms-3'>تذكرني</label>

                </div>

                <button type="submit" className="w-full p-2 mb-5 rounded-[5px] text-[14px] text-white bg-[var(--secondray-color)] font-medium text-center">تسجيل الدخول</button>


                <div className="flex items-center justify-between">

                    <Link to={'/join'} className="text-[#838383] text-[12px] underline">إنشاء حساب ؟</Link>
                    <Link to={'/forget-password'} className="text-[#838383] text-[12px] underline">نسيت كلمة السر ؟</Link>

                </div>

            </form>

        </div>
    )
}
