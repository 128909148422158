export default function Page(props) {
    return (
        <div {...props} className={`mb-10 mt-10 md:mt-12 ${props.className}`}>

            <div className='container'>

                {props.children}

            </div>

        </div>
    )
}
