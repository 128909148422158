import axios from "axios";
import Swal from "sweetalert2";

export class SwalControlar {

    constructor() {

    }

    success(title, message) {

        return Swal.fire({
            icon: 'success',
            title: title || 'شكرا...',
            text: message,
        })

    }

    warning(title, message) {

        return Swal.fire({
            icon: 'warning',
            title: title || 'تحذير...',
            text: message,
        })

    }

    rejected(title, message) {

        return Swal.fire({
            icon: 'error',
            title: title || 'خطأ...',
            text: message,
        });

    }
};

export class SecureRequest {

    constructor(token) {

        this.token = localStorage.getItem('token');

    }

    auth(asFormData) {

        return {

            Authorization: `Bearer ${localStorage.getItem('token')}`,

            'Content-Type': asFormData ? "multipart/form-data" : "application/json"

        }

    }

    post(url, data, hasAuth) {

        return axios({

            method: 'post',

            url: url,

            data: data,

            headers: hasAuth ? this.auth() : null

        })

    }

    get(url, hasAuth) {

        return axios({

            method: 'get',

            url: url,

            headers: hasAuth ? this.auth() : null

        })

    }

    put(url, data, method, asFormData) {

        return axios({

            method: method || 'put',

            url: url,

            data: data,

            headers: this.auth(asFormData),

        })


    }

    delete(url) {

        return axios({

            method: 'delete',

            url: url,

            headers: {

                Authorization: `Bearer ${this.token}`

            }

        })

    }

}