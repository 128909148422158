import { useDataGetter } from './data';

const Timer = ({ classNames, endTime, confettiContainerRef, auction }) => {

    const {
        isNearToEnd,
        formatTime,
        seconds,
        minutes,
        hours,
        days
    } = useDataGetter(endTime, confettiContainerRef, auction);

    return (
        <div className={`timer flex items-center justify-around w-fit border ${isNearToEnd ? "border-[red]" : 'border-[#CBCBCB]'} bg-white rounded-[10px] py-3 ${classNames}`} style={{ boxShadow: "0px 4px 20px 0px rgba(0, 0, 0, 0.08)" }}>

            {/* Timer component with seconds */}
            <p className={`lg:w-[82px] ${isNearToEnd ? "text-[red]" : "text-[var-(--text-color)]"} relative px-4 bg-white text-[11px] lg:text-[15px] text-center rounded-[5px] font-medium flex items-center flex-col justify-center`}>

                <span className={`font-medium`}>ثانيه</span>

                <span className='mb-0'>{formatTime(seconds)}</span>

                <div className='absolute block left-0 top-0 w-[2px] h-full bg-[#F3EEEE]'></div>

            </p>

            {/* Timer component with minutes */}
            <p className={`lg:w-[82px] ${isNearToEnd ? "text-[red]" : "text-[var-(--text-color)]"} relative px-4 bg-white text-[11px] lg:text-[15px] text-center rounded-[5px] font-medium flex items-center flex-col justify-center`}>

                <span className={`font-medium`}>دقيقه</span>

                <span className='mb-0'>{formatTime(minutes)}</span>

                <div className='absolute block left-0 top-0 w-[2px] h-full bg-[#F3EEEE]'></div>

            </p>

            {/* Timer component with hours */}
            <p className={`lg:w-[82px] ${isNearToEnd ? "text-[red]" : "text-[var-(--text-color)]"} relative px-4 bg-white text-[11px] lg:text-[15px] text-center rounded-[5px] font-medium flex items-center flex-col justify-center`}>

                <span className={`font-medium`}>ساعه</span>

                <span className='mb-0'>{formatTime(hours)}</span>

                <div className='absolute block left-0 top-0 w-[2px] h-full bg-[#F3EEEE]'></div>

            </p>

            {/* Timer component with days */}

            <p className={`lg:w-[82px] px-4 ${isNearToEnd ? "text-[red]" : "text-[var-(--text-color)]"} bg-white text-[11px] lg:text-[15px] text-center rounded-[5px] font-medium flex items-center flex-col justify-center`}>

                <span className={`font-medium`}>يوم</span>

                <span className='mb-0'>{formatTime(days)}</span>

            </p>

        </div>

    );
};

export default Timer;