import { Skeleton } from 'primereact/skeleton';
import React, { Fragment } from 'react'
import { Link } from 'react-router-dom';

export default function InteractiveActionPanel({ loading, auction }) {
    return (
        <Fragment>

            {
                loading
                    ?
                    <div className='flex items-center justify-between mb-1'>

                        <Skeleton className="relative !h-[44px] rounded-[10px]"></Skeleton>

                        <Skeleton className="relative !h-[44px] min-w-[44px] ms-2 !w-[44px] !rounded-full"></Skeleton>

                    </div>
                    :
                    <div className='flex items-center'>

                        <Link to={`/auctions/auction-details?id=${auction?.id}`} className='flex-1 me-2'>

                            <button className='text-white font-medium bg-[var(--secondray-color)] p-3 text-center w-full rounded-[5px] text-[13px] sm:text-[16px]'>التفاصيل</button>

                        </Link>

                        <button className='text-black text-[11px] font-medium bg-[#D9D9D9] rounded-full border border-[#D9D9D9] text-center'>

                            <img
                                src={auction?.member?.img || require('../../../../asstes/images/default-auction-card.png')}
                                onError={(e) => {
                                    e.target.src = require('../../../../asstes/images/default-auction-card.png');
                                }}
                                className='w-[50px] h-[44px] rounded-full object-cover'
                            />

                        </button>

                    </div>
            }

        </Fragment>
    )
}
