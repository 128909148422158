import { useEffect } from 'react';
import bell from '../../asstes/images/bell.svg';
import { getTimeDifference } from './data';

export default function Notficaitions({ visible, data, classNames, setNotficationVisible }) {

    const sortedNotifications = data?.Notifications?.sort((a, b) => {
        const dateA = new Date(a.created_at);
        const dateB = new Date(b.created_at);

        // Compare the dates in descending order
        return dateB - dateA;
    });

    useEffect(() => {

        const handleResize = () => setNotficationVisible(false);
        window.addEventListener('resize', handleResize);

        // Clean Up
        return () => window.removeEventListener('resize', handleResize);

    }, []);

    return (

        <div onClick={e => e.stopPropagation()} className={`${visible ? "opacity-100 visible scale-100" : "opacity-0 invisible scale-0"} max-h-[500px] overflow-y-auto transition w-[500px] max-w-[500px] absolute bg-white left-20 top-16 p-3 border border-[var(--secondary)] z-[1000] rounded-[10px] ${classNames}`}>

            <div className='flex items-center justify-between mb-5 pb-3 border-b border-b-[rgba(0_0_0_0.15)]'>

                <h1 className='text-[#37495F] text-[15px] font-medium'>الاشعارات ({data?.Notifications?.length})</h1>

                <img src={bell} alt='' width={'23px'} height={'23px'} />

            </div>

            {
                sortedNotifications?.map((item, index) => {

                    return (
                        <div key={index} className='flex items-center justify-between mb-3 relative sm:w-[450px]'>

                            <div className='flex items-center flex-1 overflow-hidden' style={{ width: 'calc(100% - 70px)' }}>

                                <div className='w-[33px] h-[33px] bg-[var(--secondary)] rounded-full hidden sm:flex items-center justify-center me-2'>

                                </div>

                                <div className='flex-1 text-justify'>

                                    <h2 className='text-[14px]'>إشعار</h2>
                                    <p className='text-[11px] break-words'>{item?.data}</p>

                                </div>

                            </div>

                            <p className='text-[var(--blue-bell)] w-[70px] min-w-[70px] text-end'>{getTimeDifference(item?.created_at)}</p>

                        </div>
                    )

                })
            }

        </div>
    )
}
