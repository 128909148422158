import { configureStore } from "@reduxjs/toolkit";
import auctions from "./reducers/auctions";
import currentAuctions from "./reducers/currentAuctions";
import futureAuctions from "./reducers/futureAuctions";
import expiredAuctions from "./reducers/expiredAuctions";
import filterAuctions from "./reducers/filterAuctions";

const store = configureStore({
    reducer: {
        auctions: auctions,
        currentAuctions: currentAuctions,
        futureAuctions: futureAuctions,
        expiredAuctions: expiredAuctions,
        filterAuctions: filterAuctions,
    }
});


export default store;