import { baseUrl, RequestManager, Swal } from "../data";

class Auth {

    constructor(role) {

        this.handleRole = role => {
            return role === "provider" ? "user" : "member";
        }

        this.role = this.handleRole(role);

    }

    postRegister(data) {

        return RequestManager.post(`${baseUrl}auth/register/${this.role}`, data, false).then(response => {

            // localStorage.setItem('user', JSON.stringify(response.data?.registration));

            sessionStorage.setItem('national_id', data?.national_id);

            // Navigate User to confirem nafth integration
            window.location.href = '/confrimation';

        })
            .catch(error => {

                Swal.rejected('خطأ', Object.values(error?.response?.data).join(' و ')).then(_ => {

                    if (error?.response?.data?.national_id) {

                        if (error?.response?.data?.national_id?.[0] === "الرقم الوطني مستخدم بالفعل.") {

                            window.location.href = '/login';

                        }

                    }

                })

            })
    }

    postLogin(data) {

        return RequestManager.post(`${baseUrl}auth/login`, data).then(response => {

            // // append Data to Localstorage
            localStorage.setItem('token', response.data);

            Swal.success('شكرا', 'تم التسجيل بنجاح').then(_ => window.location.reload());

        })
            .catch(error => {

                Swal.rejected('خطأ', error?.response?.data);

            })

    }

    postLogout() {

        return RequestManager.post(`${baseUrl}auth/logout`, null, true).then(response => {


        })
            .catch(error => error);
    }

    nafthIntegration(national_id) {

        RequestManager.post(`${baseUrl}nafath/integration/${national_id}`, null, true).then(response => {

            if (response.data?.random) {

                return localStorage.setItem('register-auth', response.data?.random);

            }

            return Swal.warning('إنتبة', 'يرجي الانتظار دقيقتين لارسال طلب جديد');

        })

            .catch(error => console.log(error, 'Error'));

    }

    requesToSendPassword(data, naviagte) {

        return RequestManager.post(`${baseUrl}auth/forgot/password`, data, true).then(response => {

            if (response.data?.message === "User or member not found") {

                Swal.warning(null, response.data?.message);

            } else {

                Swal.success(null, response.data?.message || `تم ارسال الكود بنجاح`).then(_ => naviagte('/'));

            }

        })

            .catch(error => Swal.rejected(null, error?.response?.data?.message || `برجاء المحاولة مرة اخري`));

    }

    resetPassword(data, naviagte) {

        return RequestManager.post(`${baseUrl}auth/reset/password`, data, true).then(response => {

            if (response.data?.message === "Invalid token") {

                Swal.warning(null, response.data?.message);

            } else {

                Swal.success(null, response.data?.message || `تم تغير كلمة المرور`).then(_ => naviagte('/login'))

            }

        })

            .catch(error => Swal.rejected(null, error.data?.message || `برجاء المحاولة مرة اخري`));

    }

    profile(setUser) {

        return RequestManager.get(`${baseUrl}information`, true).then(response => {

            const user = JSON.parse(localStorage.getItem('user'));

            const data = { ...user, ...response.data?.InformationServices };

            setUser(data);

            localStorage.setItem('user', JSON.stringify(data));

        })
            .catch(error => {

                if (error?.response?.status === 401) {

                    window.localStorage.clear();

                    window.sessionStorage.clear();

                    window.location.reload();

                }

            });

    }

}

export {
    Auth
}