import { baseUrl, RequestManager } from "../data";

class IntellectualPolicy {

    getIntellectualPolicy(state) {

        return RequestManager.get(`${baseUrl}legal/docs/copyright-policy`).then(response => {

            state(response.data?.CopyrightPolicyServices);

        }).catch(error => null);

    }


}

export {
    IntellectualPolicy
}