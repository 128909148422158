import { faSearch } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'


export default function SearchBox({ classNames, inputClassNames, iconClassNames, placeholder, ...props }) {
    return (
        <div className={`flex items-center justify-center mb-10 w-full sm:w-fit sm:m-auto relative ${classNames}`}>

            <input {...props} type='text'
                placeholder={placeholder || 'ابحث برقم الكرت'}
                className={`w-full sm:w-[570px] rounded-full border border-[var(--secondray-color)] p-3 sm:p-4 px-6 ${inputClassNames}`}
            />

            <div className={`w-[35px] sm:w-[50px] h-[35px] sm:h-[50px] absolute left-2 cursor-pointer rounded-full bg-[var(--secondray-color)] flex items-center justify-center text-white text-[20px] ${iconClassNames}`}>

                <FontAwesomeIcon icon={faSearch} className='text-[12px] sm:text-[16px]' />

            </div>

        </div>

    )
}
