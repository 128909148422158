import { baseUrl, RequestManager, Swal } from "../data";

class Contact {

    postMessage(data) {

        return RequestManager.put(`${baseUrl}message/store`, data , 'post' , true  ).then(response => {

            return Swal.success('شكرا', 'تم ارسال الرسالة بنجاح');

        }).catch(error => Swal.rejected('Error', 'Something Wrong Please Try Again'));

    }


}

export {
    Contact
}