import React from 'react'

export default function Brands() {
    return (
        <div className='col-span-12 md:col-span-4 flex items-center justify-between order-[-1] md:order-[0] mb-5 md:mb-0'>

            {
                [1, 2, 3].map(item => {
                    return <div key={item} className={`h-[47px] relative mx-3 ${item !== 2 ? "opacity-0" : ""} rounded-[10px]`} style={{ width: 'calc(90% / 3)' }}>
                        <img src={require('../../../../asstes/images/footer_logo.png')} alt='' className='absolute left-0 top-0 w-full h-full object-contain' />
                    </div>
                })
            }

        </div>
    )
}
