import { faArrowUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";

const useToggleShowButton = () => {

    const maxmumHeight = 300;

    const [toggleShow, setToggleShow] = useState(false);

    const handleScrolling = _ => {
        window.scrollY >= maxmumHeight ? setToggleShow(true) : setToggleShow(false);

    }

    useEffect(() => {

        window.addEventListener('scroll', handleScrolling);

        // Clean Up
        return () => window.removeEventListener('scroll', handleScrolling);

    }, []);

    const handleClick = () => {

        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });

    }

    return { toggleShow, handleClick };
}

export default function ScrollToTopBtn() {

    const { toggleShow, handleClick } = useToggleShowButton();


    return (
        <button onClick={handleClick} className={`bg-[var(--primary-color)] text-white rounded-full w-[50px] h-[50px] fixed right-5 bottom-[80px] sm:bottom-5 z-[100] ${toggleShow ? "opacity-100 scale-100 visible" : "invisible scale-0 opacity-0"}`} style={{ transition: 'var(--transition)' }}>
            <FontAwesomeIcon icon={faArrowUp} />
        </button>
    )
}
