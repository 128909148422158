import Pusher from 'pusher-js';
import { useDispatch, useSelector } from "react-redux";
import { setAuctions } from "../../store/reducers/auctions";
import { Auctions } from '../../apis/apis';
import { useEffect } from 'react';
import { setCurrentAuctions } from '../../store/reducers/currentAuctions';
import { filterAuctions } from '../../asstes/js/utils';
import { setExpiredAuctions } from '../../store/reducers/expiredAuctions';
import { setFutureAuctions } from '../../store/reducers/futureAuctions';

const useFetchAuctions = () => {

    const dispatch = useDispatch();

    const allAuctions = useSelector(store => store.auctions);

    useEffect(() => {

        const pusher = new Pusher('a1a38ff541924498b162', {
            cluster: 'eu',
            encrypted: true
        });


        Pusher.logToConsole = false;

        const channel = pusher.subscribe('auction-all');

        channel.bind('App\\Events\\Pusher\\AuctionsPusher', function (data) {

            auctionUtailty.getAuctions(dispatch, setAuctions);

        });

        return () => {
            channel.unbind('auction-all');
            pusher.unsubscribe('App\\Events\\Pusher\\AuctionsPusher');
            pusher.disconnect();
        };

    }, []);

    // Calling Api To Fetch Data

    const auctionUtailty = new Auctions();

    useEffect(() => {

        auctionUtailty.getAuctions(dispatch, setAuctions);

    }, []);

    useEffect(() => {

        dispatch(setCurrentAuctions(filterAuctions(allAuctions, 'CURRENT')));
        dispatch(setExpiredAuctions(filterAuctions(allAuctions, 'EXPIRED')));
        dispatch(setFutureAuctions(filterAuctions(allAuctions, 'FUTURE')));

    }, [allAuctions]);

}

export default function fetchData() {
    return { useFetchAuctions }
}
