import { Header, Footer, MobileFooter, MobileHeader } from './layouts';
import ScrollReset from './components/ScrollReset/ScrollReset';
import { Guard, ScrollToTopBtn } from './components';
import { fetchData, customEffect } from './hooks';
import routes from './routes/routes';
import './asstes/styles/App.css';
import './asstes/styles/responsive.css';
import "aos/dist/aos.css";
import Loader from './pages/Loader';
import { useContext } from 'react';
import { AppContext } from './context/AppContext';

function App() {

  const { useFetchAuctions } = fetchData();

  const { useAppEffects } = customEffect();

  const { isRenderHeader, isRenderFooter } = useAppEffects();

  const { isLoading } = useContext(AppContext);

  useFetchAuctions();

  return (

    <div className="App">

      {isRenderHeader ? <Header /> : null}

      {isRenderHeader ? <MobileHeader /> : null}

      {isLoading ? <Loader isLoading={isLoading} /> : null}

      {/* To Reset Scroll When Route Change */}
      <ScrollReset />

      <ScrollToTopBtn />

      <Guard />

      {/* Pages Routes */}
      {routes()}

      {isRenderFooter ? <Footer /> : null}

      {isRenderFooter ? <MobileFooter /> : null}

    </div>

  );
}

export default App;
