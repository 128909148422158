import { RequestManager, Swal, baseUrl } from "../data";

class NotificationService {

    fetchNotifications(state) {

        return RequestManager.get(`${baseUrl}notifications`, true)

            .then(response => {
                return state(response.data);

            }).catch(error => {

                Swal.rejected(null, error?.response?.data?.message || `برجاء المحاولة مرة اخري`);

            });

    }

    updateNotifications() {

        return RequestManager.put(`${baseUrl}notifications/read-at`, {}, 'put',)

            .then(response => {

                return;

            }).catch(error => {

                Swal.rejected(null, error?.response?.data?.message || `برجاء المحاولة مرة اخري`);

            });

    }

}

export {
    NotificationService
}