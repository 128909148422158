import { baseUrl, RequestManager } from "../data";

class Terms {

    getTerms(state) {

        return RequestManager.get(`${baseUrl}legal/docs/privacy-policy`).then(response => {

            state(response.data?.PrivacyPolicyServices);

        }).catch(error => null);

    }


}

export {
    Terms
}