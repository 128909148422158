import { useEffect, useState } from "react";
import { confettiParticle } from "../../asstes/js/utils";
import confetti from "canvas-confetti";

const useDataGetter = (endTime, confettiContainerRef, auction) => {

    let interval;

    // Check If The auction type will be in the feature
    endTime = auction?.type_date === 'FUTURE' ? auction?.start_date : endTime;

    const [isNearToEnd, setIsNearToEnd] = useState(false);

    // Calculate the remaining time from the current time to the end time
    const calculateTime = () => {

        const currentTime = new Date().getTime();

        const targetTime = new Date(endTime).getTime();

        const remainingTime = targetTime - currentTime;

        if (remainingTime <= (60000 * 10) && auction?.type_date === 'CURRENT') {

            setIsNearToEnd(true);

        }

        if (remainingTime <= 1000 && auction?.type_date == "CURRENT") {
            confettiContainerRef && confettiParticle(confetti, confettiContainerRef?.current);

            clearInterval(interval);

        }
        const days = Math.floor(remainingTime / (24 * 60 * 60 * 1000));
        const hours = Math.floor((remainingTime % (24 * 60 * 60 * 1000)) / (60 * 60 * 1000));
        const minutes = Math.floor((remainingTime % (60 * 60 * 1000)) / (60 * 1000));
        const seconds = Math.floor((remainingTime % (60 * 1000)) / 1000);

        return { days, hours, minutes, seconds };
    };

    // State variables for days, hours, minutes, and seconds
    const [days, setDays] = useState(0);

    const [hours, setHours] = useState(0);

    const [minutes, setMinutes] = useState(0);

    const [seconds, setSeconds] = useState(0);

    // Function to format the time value with leading zeros
    const formatTime = (value) => {
        return value.toString().padStart(2, '0');
    };

    // Function to calculate and update the time values
    const getTime = () => {
        const { days, hours, minutes, seconds } = calculateTime();

        // Check if the remaining time is negative
        if (days > 0 || hours > 0 || minutes > 0 || seconds > 0) {
            setDays(days);
            setHours(hours);
            setMinutes(minutes);
            setSeconds(seconds);
        } else {
            setDays(0);
            setHours(0);
            setMinutes(0);
            setSeconds(0);
        }

    };

    // Run the getTime function every second using useEffect
    useEffect(() => {

        interval = setInterval(() => getTime(), 1000);

        return () => clearInterval(interval);

    }, [endTime]);

    return {
        isNearToEnd,
        formatTime,
        seconds,
        minutes,
        hours,
        days
    };

}

export {
    useDataGetter
}