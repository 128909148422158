import { useRef } from 'react';
import roundedBox from '../../../../asstes/images/rounded-box.svg';
import Timer from '../../../Timer/Timer';

export default function TimeRemainingPanel({ origin, auction }) {

    const confettiContainerRef = useRef();

    return (
        <div ref={confettiContainerRef} className='flex items-center sm:px-5 absolute left-0 top-[-60px] lg:top-0 lg:relative'>

            <div className='lg:min-w-[300px] me-8'>

                <Timer auction={auction} endTime={auction?.end_date} classNames={'!rounded-[10px] -me-3 sm:me-0'} />

            </div>

            <div className="relative sm:h-[55px] mt-4">

                <span className='max-h-full w-[90px] -left-8 -top-4 absolute z-10'>

                    <img src={roundedBox} alt='' />

                </span>

                <h1 className="text-[var(--secondray-color)] text-center font-medium relative z-10">{origin?.id}</h1>
                <h1 className="text-[var(--secondray-color)] text-center text-[6px] font-medium relative z-10">رقم الاصل</h1>

            </div>

        </div>

    )
}
