import React, { Fragment, useState } from 'react'
import { Link } from 'react-router-dom';
import { Notficaitions, PrivacyRoutes } from '../../components';
import { useDataGetter } from './data';
import { user_dashboard_url } from '../../apis/data';

export default function MobileFooter() {

    const token = localStorage.getItem('token');

    const [visible, setVisible] = useState(false);

    const {
        notficationVisible,
        setNotficationVisible,
        isLogin,
        data,
    } = useDataGetter();


    return (
        <Fragment>

            <footer className={`sm:hidden block fixed w-full bottom-0 bg-white z-[99999999]`} style={{ boxShadow: '0 0 10px rgba(0, 0, 0, 0.2)' }}>

                {/* Not Login Part */}
                <div onClick={e => e.stopPropagation()} className={`${isLogin ? "hidden" : "flex"} relative items-center justify-center text-[var(--secondray-color)] p-4`}>

                    <div className='w-[40px] h-[40px] bg-[#d3e6e3] rounded-full absolute -bottom-5 left-10'>

                    </div>

                    <Link to={'/login'} >

                        <div className='flex items-center'>

                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                <path d="M15 3H19C19.5304 3 20.0391 3.21071 20.4142 3.58579C20.7893 3.96086 21 4.46957 21 5V19C21 19.5304 20.7893 20.0391 20.4142 20.4142C20.0391 20.7893 19.5304 21 19 21H15" stroke="var(--secondray-color)" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                <path d="M15.3536 12.3536C15.5488 12.1583 15.5488 11.8417 15.3536 11.6464L12.1716 8.46447C11.9763 8.2692 11.6597 8.2692 11.4645 8.46447C11.2692 8.65973 11.2692 8.97631 11.4645 9.17157L14.2929 12L11.4645 14.8284C11.2692 15.0237 11.2692 15.3403 11.4645 15.5355C11.6597 15.7308 11.9763 15.7308 12.1716 15.5355L15.3536 12.3536ZM5 11.5C4.72386 11.5 4.5 11.7239 4.5 12C4.5 12.2761 4.72386 12.5 5 12.5V11.5ZM15 11.5H5V12.5H15V11.5Z" fill="var(--secondray-color)" />
                            </svg>

                            <h2 className='text-[20px] ms-2'>سجل معنا الان</h2>

                        </div>

                    </Link>

                    <div onClick={e => e.stopPropagation()} className='absolute right-5 text-center flex items-center justify-center flex-col'>

                        <span onClick={_ => setVisible(perv => !perv)}>

                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                <path d="M12 13C12.5523 13 13 12.5523 13 12C13 11.4477 12.5523 11 12 11C11.4477 11 11 11.4477 11 12C11 12.5523 11.4477 13 12 13Z" fill="var(--text-color)" fillOpacity="0.5" stroke="var(--secondray-color)" strokeOpacity="0.5" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                <path d="M12 6C12.5523 6 13 5.55228 13 5C13 4.44772 12.5523 4 12 4C11.4477 4 11 4.44772 11 5C11 5.55228 11.4477 6 12 6Z" fill="var(--text-color)" fillOpacity="0.5" stroke="var(--secondray-color)" strokeOpacity="0.5" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                <path d="M12 20C12.5523 20 13 19.5523 13 19C13 18.4477 12.5523 18 12 18C11.4477 18 11 18.4477 11 19C11 19.5523 11.4477 20 12 20Z" fill="var(--text-color)" fillOpacity="0.5" stroke="var(--secondray-color)" strokeOpacity="0.5" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>

                        </span>

                        <PrivacyRoutes visible={visible} setVisible={setVisible} classNames={'right-[15px] !bottom-16'} inFooter={true} />

                        <h6 className='text-[var(--secondray-color)] text-[11px]'>المزيد</h6>

                    </div>


                </div>

                {/* Login Part */}
                <div className={`p-4 ${isLogin ? "flex" : "hidden"} items-center justify-between relative`}>

                    <div onClick={e => e.stopPropagation()} className='text-center flex items-center justify-center flex-col'>

                        <span onClick={_ => setVisible(perv => !perv)}>

                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                <path d="M12 13C12.5523 13 13 12.5523 13 12C13 11.4477 12.5523 11 12 11C11.4477 11 11 11.4477 11 12C11 12.5523 11.4477 13 12 13Z" fill="var(--text-color)" fillOpacity="0.5" stroke="var(--secondray-color)" strokeOpacity="0.5" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                <path d="M12 6C12.5523 6 13 5.55228 13 5C13 4.44772 12.5523 4 12 4C11.4477 4 11 4.44772 11 5C11 5.55228 11.4477 6 12 6Z" fill="var(--text-color)" fillOpacity="0.5" stroke="var(--secondray-color)" strokeOpacity="0.5" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                <path d="M12 20C12.5523 20 13 19.5523 13 19C13 18.4477 12.5523 18 12 18C11.4477 18 11 18.4477 11 19C11 19.5523 11.4477 20 12 20Z" fill="var(--text-color)" fillOpacity="0.5" stroke="var(--secondray-color)" strokeOpacity="0.5" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>

                        </span>

                        <PrivacyRoutes visible={visible} setVisible={setVisible} classNames={'right-[15px] !bottom-20'} />

                        <h6 className='text-[var(--secondray-color)] text-[11px]'>المزيد</h6>

                    </div>

                    <div className='text-center flex items-center justify-center flex-col'>

                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                            <path d="M3.3335 17.5003V11.667" stroke="var(--secondray-color)" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M3.3335 8.33333V2.5" stroke="var(--secondray-color)" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M10 17.5V10" stroke="var(--secondray-color)" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M10 6.66667V2.5" stroke="var(--secondray-color)" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M16.6665 17.4997V13.333" stroke="var(--secondray-color)" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M16.6665 10V2.5" stroke="var(--secondray-color)" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M0.833496 11.667H5.8335" stroke="var(--secondray-color)" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M7.5 6.66699H12.5" stroke="var(--secondray-color)" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M14.1665 13.333H19.1665" stroke="var(--secondray-color)" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>

                        <a href={`${user_dashboard_url}?token=${token}`} target={'_blank'} className='text-[var(--secondray-color)] text-[11px]'>لوحه التحكم</a>

                    </div>
                    <Link to={'/auctions'} className='text-center'>

                        <img src={require('../../asstes/images/footer-icon.png')} className='absolute -top-3 z-[2000]' />

                        <h6 className='text-[var(--secondray-color)] text-[11px] mt-[1.45rem]'>المزادات</h6>

                    </Link>
                    <div onClick={e => {
                        e.stopPropagation();
                        setNotficationVisible(perv => !perv)
                    }} className='text-center flex items-center justify-center flex-col relative'>

                        {
                            data?.totalNullReadAt
                                ?
                                <div className='w-[6px] h-[6px] rounded-full bg-[red] absolute top-2 right-[19px] transition'></div>
                                :
                                null

                        }

                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
                            <path d="M18 8.23535C18 6.64405 17.3679 5.11793 16.2426 3.99271C15.1174 2.86749 13.5913 2.23535 12 2.23535C10.4087 2.23535 8.88258 2.86749 7.75736 3.99271C6.63214 5.11793 6 6.64405 6 8.23535C6 15.2354 3 17.2354 3 17.2354H21C21 17.2354 18 15.2354 18 8.23535Z" fill="white" stroke="var(--secondray-color)" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M13.73 21.2354C13.5542 21.5384 13.3019 21.79 12.9982 21.9649C12.6946 22.1398 12.3504 22.2318 12 22.2318C11.6496 22.2318 11.3054 22.1398 11.0018 21.9649C10.6982 21.79 10.4458 21.5384 10.27 21.2354" stroke="var(--secondray-color)" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>

                        <h6 className='text-[var(--secondray-color)] text-[11px]'>الاشعارات</h6>

                    </div>
                    <Link to={'/'} className='text-center flex items-center justify-center flex-col'>

                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <path d="M3 9L12 2L21 9V20C21 20.5304 20.7893 21.0391 20.4142 21.4142C20.0391 21.7893 19.5304 22 19 22H5C4.46957 22 3.96086 21.7893 3.58579 21.4142C3.21071 21.0391 3 20.5304 3 20V9Z" stroke="var(--secondray-color)" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>

                        <h6 className='text-[var(--secondray-color)] text-[11px]'>الرئيسية</h6>

                    </Link>


                </div>

            </footer>

            <Notficaitions
                classNames={'!bottom-[80px] !left-2 !max-w-[95%] !top-[initial] !fixed'}
                visible={notficationVisible}
                setNotficationVisible={setNotficationVisible}
                data={data}
            />

        </Fragment>
    )
}
