export const headerTemplate = (item, index) => {
    return <div className='flex items-center'>

        <div className="w-[2px] h-[25px] -ms-2 me-3 bg-[var(--secondray-color)]">

        </div>

        <h1 className='text-[var(--gray-color)] font-medium text-[14px] sm:text-[20px]'>{item.question}</h1>

    </div>
}
