import { useEffect } from 'react';
import { setScrollVisibility } from '../../asstes/js/utils';
import './loader.css';

export default function Loader({ classNames, isLoading }) {

    useEffect(() => {

        isLoading ? setScrollVisibility(isLoading) : setScrollVisibility(isLoading);

        // Clean Up
        return () => { };

    }, [isLoading]);

    return (
        <div className={`w-[100vw] h-[100vh] left-0 top-0 bg-[#6AA9A1] fixed flex items-center justify-center z-[3000] ${classNames}`}>

            <span className="loader"></span>

        </div>
    )
}
