import React from 'react'
import { Brands, Privacy, SocialMediaLinks } from './components'

export default function Footer() {
    return (
        <footer className='bg-[#f5f6f7] py-5 hidden sm:block'>

            <div className='container grid grid-cols-12 gap-3 md:gap-10'>

                <SocialMediaLinks />

                <Privacy />

                <Brands />

            </div>

        </footer>
    )
}
