import { baseUrl, RequestManager } from "../data";

class Questions {

    getQuestions(state) {

        return RequestManager.get(`${baseUrl}legal/docs/frequently-asked-question`).then(response => {

            state(response.data?.FrequentlyAskedQuestionServices);

        }).catch(error => null);

    }


}

export {
    Questions
}