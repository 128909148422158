
import { faClock } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Auth } from "../../apis/apis";
const { DateTime } = require('luxon');

const filterAuctions = (auctions, type_date) => {

    const newAuctions = auctions?.filter(item => item?.type_date === type_date);

    return newAuctions;

}

function displayIslamicDate(dateString, type) {
    // Convert the input string to a Date object
    const date = new Date(dateString);

    // Extract the day, month, and year from the Date object
    const day = date.getDate();
    const month = date.getMonth();
    const year = date.getFullYear();

    function convertToHijri(dateString, lang) {

        const date = new Date(dateString);

        // Increment month by 1 to match Luxon's one-based month
        const month = date.getMonth() + 1;

        const gregorianDate = DateTime.fromObject({
            year: date.getFullYear(),
            month: month,
            day: date.getDate(),
            hour: date.getHours(),
            minute: date.getMinutes(),
            second: date.getSeconds(),
            millisecond: date.getMilliseconds(),

        });

        if (gregorianDate?.invalid) return 'invalid date';
        const islamicDate = gregorianDate.setLocale(lang, { outputCalendar: 'islamic' });

        const formatter = new Intl.DateTimeFormat(lang, {
            day: 'numeric',
            month: 'long',
            year: 'numeric',
            calendar: 'islamic'
        });

        return formatter?.format(islamicDate?.toJSDate());
    }
    // Format the result string

    const hijireDate = `${convertToHijri(`${year}-${String(month + 1).padStart(2, '0')}-${String(day).padStart(2, '0')}`, 'ar')}`;


    const result = `${type === 'start' ? "يبدأ" : "ينتهي"}`;

    return (
        <div className='flex items-center mb-4'>

            <div className='min-w-[21px] h-[21px] flex items-center justify-center bg-[#F0F0F0] rounded-[3px] me-3'>

                <FontAwesomeIcon icon={faClock} className='text-[var(--secondary-text-color)] text-[13px]' />

            </div>

            <h6 className='font-medium text-[11px]'>

                <span className='text-[var(--gray-color)]'> {type === 'start' ? `${result} ${(parseInt(hijireDate) - 1) || 1} ${hijireDate.slice(2)}` : `${result} ${hijireDate}`} </span>

            </h6>

            <h6 className='text-[var(--secondary-text-color)] font-medium text-[11px] sm:text-[13px] sm:ms-4'>
                {dateString} م
            </h6>

        </div>
    );
}

const useNavigationTypeControl = (defaultType) => {

    const [activeIndex, setActiveIndex] = useState(0);

    const [auctionsType, setAuctionsType] = useState(defaultType);

    const [loading, setLoading] = useState(false);

    const auctions = useSelector(store => store[auctionsType]);

    const handleClick = (index, type) => {

        setActiveIndex(index);

        setAuctionsType(type);

        setLoading(true);

        const timeOut = setTimeout(() => {
            setLoading(false);
        }, 2000);

        return () => clearTimeout(timeOut);

    }

    return { handleClick, activeIndex, loading, setLoading, auctions, auctionsType, setAuctionsType };

}

const isLoggedIn = () => localStorage.getItem('token');

const logOut = (setIsLoading) => {

    const authUtailty = new Auth();

    setIsLoading(true);

    authUtailty.postLogout().then(_ => {

        localStorage.clear();

        sessionStorage.clear();

        return window.location.href = '/login';

    }).finally(_ => setIsLoading(false));

}

function confettiParticle(confetti, parentElement) {

    if (!parentElement) return null;

    // Create a new canvas element
    var myCanvas = document.createElement('canvas');

    // Append the canvas to the parent element
    parentElement.prepend(myCanvas);

    // Create confetti using the new canvas
    var myConfetti = confetti?.create(myCanvas, {
        resize: true
    });

    // Trigger the confetti animation
    var confettiAnimation = myConfetti({
        particleCount: 500,
        spread: 160,
        origin: {
            x: 0.5,
            // since they fall down, start a bit higher than random
            y: 0.5
        }
    });

    // Listen for the confetti animation end event
    confettiAnimation.then(() => {
        // Remove the canvas element from the parent
        parentElement.removeChild(myCanvas);
    });


    return confettiAnimation;
}

const setScrollVisibility = status => {
    const body = document.body;
    return status ? body.style.cssText += "overflow:hidden; max-height: 100vh;" : body.style.cssText += "overflow: initial; max-height: initial;";
}

const numberFormat = number => {
    let formattedNumber = number?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    return formattedNumber;
}


export {
    filterAuctions,
    useNavigationTypeControl,
    displayIslamicDate,
    isLoggedIn,
    logOut,
    setScrollVisibility,
    confettiParticle,
    numberFormat,
}
