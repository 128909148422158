import { baseUrl, RequestManager } from "../data";

function sort_by_amount() {

    return function (elem1, elem2) {

        if (elem1.amount > elem2.amount) {

            return -1;

        } else if (elem1.amount < elem2.amount) {

            return 1;

        } else {

            return 0;

        }

    };
}

class Auctions {

    getAuctions(dispatch, setAuctions) {

        return RequestManager.get(`${baseUrl}main/index`).then(response => {

            return dispatch(setAuctions(response.data));

        }).catch(error => null);

    }

    isAuctionExist(id, navigate) {

        return RequestManager.get(`${baseUrl}main/main-auction/${id}`).then(response => {

            if (!response.data) {

                navigate('/');

            }

        }).catch(error => null);

    }

    like(originId) {

        return RequestManager.put(`${baseUrl}user-activity/favorite/${originId}`, originId).then(response => {

            return;

        }).catch(error => null);

    }

    getAuctionBidders(setAuctions, subid) {

        return RequestManager.get(`${baseUrl}main/bidders/${subid}`).then(response => {

            setAuctions(response.data.sort(sort_by_amount()));

        }).catch(error => null);

    }


}

export {
    Auctions
}