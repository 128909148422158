import { useLocation } from 'react-router';
import { useEffect } from 'react';

const useResetScroll = (router) => {

    useEffect(() => {

        window.scrollTo(0, 0);

    }, [router.pathname]);

}

// Thats to Reset the scroll to zero when user change the route
export default function ScrollReset() {

    const router = useLocation();

    useResetScroll(router);

    return null;
}
