// importing require modules
import { Suspense } from "react";
import { Route, Routes } from "react-router-dom";

import {
    Loader,
    Home,
    Questions,
    Policy,
    Terms,
    IntellectualPolicy,
    AuctionDetails,
    AuctionDashboard,
    Auctions,
    Landing,
    Join,
    Login,
    Contact,
    Register,
    NotFound,
    ResetPassword,
    Confrimation
} from './data';

export default function routes() {
    return (
        // Prevent loading errors with fallback Loader
        <Suspense fallback={<Loader />}>

            {/* Route configuration */}
            <Routes>

                {/* Route for homepage */}
                <Route path="" element={<Home />} />

                <Route path="/landing" element={<Landing />} />

                <Route path="questions" element={<Questions />} />

                <Route path="privacy-policy" element={<Policy />} />

                <Route path="terms" element={<Terms />} />

                <Route path="intellectual-policy" element={<IntellectualPolicy />} />

                <Route path="auctions/auction-details" element={<AuctionDetails />} />

                <Route path="auctions/auction-dashboard" element={<AuctionDashboard />} />

                <Route path="auctions" element={<Auctions />} />

                <Route path="join" element={<Join />} />

                <Route path="login" element={<Login />} />

                <Route path="contact" element={<Contact />} />

                <Route path="register" element={<Register />} />

                <Route path="forget-password" element={<ResetPassword />} />

                <Route path="confrimation" element={<Confrimation />} />

                <Route path="*" element={<NotFound />} />

                {/* End Of Route configuration */}
            </Routes>

        </Suspense >
    );
}
