import React, { createContext, useEffect, useState } from 'react';
import { Auth } from '../apis/apis';
import { isLoggedIn } from '../asstes/js/utils';

const AppContext = createContext();

const AppProvider = ({ children }) => {

    const [isLoading, setIsLoading] = useState(false);

    const authUtailty = new Auth();

    const isLogin = isLoggedIn();

    const [user, setUser] = useState();

    useEffect(() => {

        if (isLogin) {

            setIsLoading(true);

            authUtailty.profile(setUser).finally(_ => setIsLoading(false));

        }

    }, []);

    return (
        <AppContext.Provider value={{ isLoading, setIsLoading, user }}>
            {children}
        </AppContext.Provider>
    );
};

export { AppContext, AppProvider };