import { faInstagram, faLinkedin, faSnapchat, faYoutube } from "@fortawesome/free-brands-svg-icons"
import { faX } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Link } from "react-router-dom"

const iconsData = [
    { icon: faLinkedin, url: "https://www.linkedin.com/in/abram-auctions-a743492a6/" },
    { icon: faYoutube, url: "https://youtu.be/i-h6RMzOczE?si=ziRjBaV3mDZl9i8e" },
    { icon: faX, url: "https://x.com/sa_ibram?s=11&t=TVHuis6HAj4yWni7fEVf4g" },
    { icon: faInstagram, url: "https://www.instagram.com/abram.sa_?igshid=YzVkODRmOTdmMw%3D%3D&utm_source=qr" },
    { icon: faSnapchat, url: "https://t.snapchat.com/J4zzC6TC" },
];


export default function SocialMediaLinks() {
    return (

        <div className='col-span-12 md:col-span-3 lg:col-span-4 flex items-center justify-center mb-5 md:mb-0'>


            {
                iconsData.map((item, index) => {
                    return <Link to={item.url} target="_blank" key={index}><FontAwesomeIcon icon={item.icon} className="lg:mx-5 mx-2 text-[25px]" /></Link>
                })
            }


        </div>
    )
}
