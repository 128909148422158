import { Navigate, useLocation } from "react-router-dom";
import { isLoggedIn } from "../../asstes/js/utils";

export default function Guard() {

  const isLogin = isLoggedIn();

  const arrOfNotAvilableRoutes = ['/login', '/register', '/join',];

  const location = useLocation().pathname;


  return (
    isLogin
      ?
      arrOfNotAvilableRoutes.includes(location)
        ?
        <Navigate to={'/'} />
        :
        null
      :
      null
  )
}
