import { baseUrl, RequestManager } from "../data";

class Policy {

    getPolicy(state) {

        return RequestManager.get(`${baseUrl}legal/docs/terms-condition`).then(response => {

            state(response.data?.TermsAndConditionServices);

        }).catch(error => null);

    }


}

export {
    Policy
}