import { createSlice } from "@reduxjs/toolkit";

const auctions = createSlice({

    name: "auctions",

    initialState: [],

    reducers: {

        setAuctions: (state, action) => {

            return state = action.payload;

        }
    }
});

export default auctions.reducer;


export const { setAuctions } = auctions.actions;