import React from 'react'
import { Link } from 'react-router-dom'

export default function MobileHeader() {
    return (

        <header className='block sm:hidden pt-10'>

            <Link to={''}>

                <img src={require('../../asstes/images/horz-logo.png')} className='mb-5 m-auto w-[185px] object-cover' />

            </Link>

        </header>

    )
}
