import React from 'react'
import { Link, NavLink } from 'react-router-dom'
import { isLoggedIn } from '../../../../asstes/js/utils';
import { useState } from 'react';
import { user_dashboard_url } from '../../../../apis/data';
import { PrivacyRoutes } from '../../../../components';


export default function HeaderRoutes() {

    const isLogin = isLoggedIn();

    const token = localStorage.getItem('token');

    const [visible, setVisible] = useState(false);

    return (

        <div className='flex'>

            <Link to={'/'} className='me-8'>

                <img src={require('../../../../asstes/images/logo.png')} alt='' className='w-[145px] object-cover' />

            </Link>

            <ul className='flex items-center header-routes'>

                <li className='me-6 font-medium text-[12px] xl:text-[16px] text-[#AEC1C5] text-center'>
                    <NavLink to={'/'} end>الرئيسية</NavLink>
                </li>

                <li className='me-6 hidden lg:block font-medium text-[12px] xl:text-[16px] text-[#AEC1C5] text-center'>
                    <NavLink to={'/auctions'} end>المزادات</NavLink>
                </li>

                <li className='me-6 hidden lg:block font-medium text-[12px] xl:text-[16px] text-[#AEC1C5] text-center'>
                    <NavLink to={'/contact'} end>تواصل معنا</NavLink>
                </li>

                <div onClick={e => e.stopPropagation()} className='relative hidden lg:block me-6'>

                    <li className='me-6 cursor-pointer font-medium text-[12px] xl:text-[16px] text-[#AEC1C5] text-center' onClick={e => setVisible(perv => !perv)}>عن منصة إبرام</li>

                    <PrivacyRoutes visible={visible} setVisible={setVisible} />

                </div>

                {
                    isLogin &&
                    <li className='me-6 font-medium text-[12px] xl:text-[16px] text-[#AEC1C5] text-center'>
                        <a href={`${user_dashboard_url}?token=${token}`} target='_blank'>لوحة التحكم</a>
                    </li>
                }

            </ul>

        </div>
    )
}
