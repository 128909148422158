import { createSlice } from "@reduxjs/toolkit";

const filterAuctions = createSlice({

    name: "filterAuctions",

    initialState: [],

    reducers: {

        setFilterAuctions: (state, action) => {

            const filterItems = action.payload.auctions.filter(item => (String(item?.id)?.includes(action.payload?.keywrods) || String(item?.auction_title)?.includes(action.payload?.keywrods)));

            return state = filterItems;

        }
    }
});

export default filterAuctions.reducer;


export const { setFilterAuctions } = filterAuctions.actions;