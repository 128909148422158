import { useContext } from 'react'
import { useState } from 'react';
import { Auth } from '../../apis/apis';
import { useFormik } from 'formik';
import { AppContext } from '../../context/AppContext';
import Swal from 'sweetalert2';

const useDataGetter = setVisible => {

    const [checked, setChecked] = useState(false);

    const AuthUtailty = new Auth(null);

    const { setIsLoading } = useContext(AppContext);
    const data = useFormik({

        initialValues: {
            national_id: "",
            password: "",
        },

        onSubmit: (values) => {

            if (!values.national_id || !values.password) {

                Swal.fire('تحذير', "من فضلك قم بملء الحقول", 'warning');

            } else {

                setIsLoading(true);

                AuthUtailty.postLogin(values).then(_ => setVisible && setVisible(false)).finally(_ => setIsLoading(false));

            }

        }

    });


    return { data, checked, setChecked };
}

export {
    useDataGetter
}