import { DataView } from 'primereact/dataview';
import { Message } from 'primereact/message';

export default function DataTable({ data, gridItem, countOfRows, messageText }) {

    return (
        // Render a DataView component from PrimeReact with the products array as the value prop, countOfRows as the number of rows per page, and itemTemplate as the function to render each item
        <div className="card">

            <DataView value={data} paginator={data?.length > 9} rows={countOfRows || 9} emptyMessage={<Message severity="error" icon={false} text={messageText || "No Data Found"} />} itemTemplate={gridItem} layout={'grid'} />

        </div>
    )
}
