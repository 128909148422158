import React, { useContext, useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom';
import { AppContext } from '../../context/AppContext';
import { isLoggedIn, logOut } from '../../asstes/js/utils';

export default function PrivacyRoutes({ visible, setVisible, classNames, inFooter }) {

    const { setIsLoading } = useContext(AppContext);

    const isLogin = isLoggedIn();

    const locations = [
        { name: 'الأسئلة الشائعة', url: '/questions' },
        { name: 'الشروط و الأحكام', url: '/privacy-policy' },
        { name: 'سياسة الخصوصية', url: '/terms' },
        { name: 'تواصل معنا', url: '/contact' },
        { name: 'سياسة حقوق الملكية الفكرية', url: '/intellectual-policy' }
    ];

    useEffect(() => {

        const handleCloseMnue = () => setVisible(false);

        window.addEventListener('click', handleCloseMnue);

        // Clean Up
        return () => window.removeEventListener('click', handleCloseMnue);

    });

    return (
        <ul className={`absolute right-20 bottom-[-190px] rounded-[10px] py-3 w-[213px] transition z-[2001] bg-white shadow-lg border border-[#34D1BA80] ${visible ? "visible scale-100 opacity-100" : "invisible scale-0 opacity-0"} ${classNames}`}>

            {
                locations.map((item, index) => {

                    return (
                        inFooter
                            ?
                            <li key={index} className={`mb-3 font-medium text-[12px] ${index + 1 !== locations.length ? "border-b pb-2" : ""} sm:text-[13px] text-[#AEC1C5]`}>
                                <NavLink className={'drop-active'} to={item.url} end>{item.name}</NavLink>
                            </li>
                            :
                            item.url !== '/contact'
                                ?
                                <li key={index} className={`mb-3 font-medium text-[12px] border-b pb-2 sm:text-[13px] text-[#AEC1C5]`}>
                                    <NavLink className={'drop-active'} to={item.url} end>{item.name}</NavLink>
                                </li>
                                :
                                null
                    )

                })
            }

            {
                isLogin
                    ?
                    <li onClick={_ => logOut(setIsLoading)} className={`font-medium text-[12px] sm:text-[13px] text-[#AEC1C5]`}>تسجيل الخروج</li>
                    :
                    null
            }

        </ul>
    )
}
