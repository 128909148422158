import { lazy } from 'react';

// ##Lazy Function To Load Page Easly Importing From React 
const Loader = lazy(() => import('../pages/Loader'));
const Home = lazy(() => import('../pages/Home'));
const Questions = lazy(() => import('../pages/Questions'));
const Policy = lazy(() => import('../pages/Policy'));
const Terms = lazy(() => import('../pages/Terms'));
const IntellectualPolicy = lazy(() => import('../pages/IntellectualPolicy'));
const AuctionDetails = lazy(() => import('../pages/AuctionDetails'));
const AuctionDashboard = lazy(() => import('../pages/AuctionDashboard'));
const Auctions = lazy(() => import('../pages/Auctions'));
const Landing = lazy(() => import('../pages/Landing'));
const Join = lazy(() => import('../pages/Join'));
const Login = lazy(() => import('../pages/Login'));
const Contact = lazy(() => import('../pages/Contact'));
const Register = lazy(() => import('../pages/Register'));
const ResetPassword = lazy(() => import('../pages/ResetPassword'));
const NotFound = lazy(() => import('../pages/NotFound'));
const Confrimation = lazy(() => import('../pages/Confrimation'));


export {
    Loader,
    Home,
    Questions,
    Policy,
    Terms,
    IntellectualPolicy,
    AuctionDetails,
    AuctionDashboard,
    Auctions,
    Landing,
    Join,
    Login,
    Contact,
    Register,
    NotFound,
    Confrimation,
    ResetPassword,
}