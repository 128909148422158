import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBell } from "@fortawesome/free-regular-svg-icons";
import { faRightFromBracket } from "@fortawesome/free-solid-svg-icons";
import { logOut } from "../../../../asstes/js/utils";
import { useNavigate } from "react-router-dom";
import { Fragment } from "react";
import { useDataGetter } from "./data";
import { Notficaitions } from "../../../../components";


export default function Auth() {

    const navigate = useNavigate();

    const {
        notficationVisible,
        setNotficationVisible,
        isLogin,
        data,
        setIsLoading
    } = useDataGetter();

    return (

        <Fragment>

            <div className="flex items-center">

                {
                    isLogin &&
                    <div onClick={e => {
                        e.stopPropagation();
                        setNotficationVisible(perv => !perv);
                    }} className="ms-3 w-[37px] h-[37px] bg-[#eaeaea] rounded-[5px] flex items-center justify-center relative">

                        {
                            data?.totalNullReadAt
                                ?
                                <div className='w-[7px] h-[7px] rounded-full bg-[red] absolute top-2 right-2 transition'></div>
                                :
                                null
                        }

                        <FontAwesomeIcon icon={faBell} className="opacity-60 cursor-pointer transition hover:text-[var(--primary-color)]" />

                    </div>
                }

                <div onClick={_ => isLogin ? logOut(setIsLoading) : navigate('/login')} className="flex items-center">

                    <div className="ms-3 w-[37px] h-[37px] bg-[#eaeaea] rounded-[5px] flex items-center justify-center cursor-pointer">

                        <FontAwesomeIcon icon={faRightFromBracket} className={`opacity-60 ${isLogin ? "rotate-[180deg]" : ""} cursor-pointer transition hover:text-[var(--primary-color)]`} />

                    </div>

                    <p className="ms-3 font-medium text-[var(--secondray-color)] cursor-pointer"> {isLogin ? "الخروج" : "تسجيل الدخول"}</p>

                </div>
            </div>

            <Notficaitions visible={notficationVisible} setNotficationVisible={setNotficationVisible} data={data} />

        </Fragment>

    )
}
