import React from 'react'

export default function Privacy() {
    return (
        <div className='col-span-12 md:col-span-5 lg:col-span-4'>

            <h2 className='font-medium mb-3 text-center  text-[13px] xl:text-[16px]'>جميع الحقوق محفوظة لمنصة إبرام © 2022</h2>

            <div className='flex items-center justify-between'>

                <a href="/intellectual-policy">
                    <h4 className='font-medium text-[#4c4c4c] text-[13px] xl:text-[16px]'>سياسة حقوق الملكية الفكرية</h4>
                </a>

                <a href="/terms">
                    <h4 className='font-medium text-[#4c4c4c] text-[13px] xl:text-[16px]'>سياسة الخصوصية</h4>
                </a>
                

            </div>

        </div>
    )
}
